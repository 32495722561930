import React from "react"

import Logo from "../logo/logo"

import "./footer.scss"
import { Link } from "gatsby"

var Footer = props => (
  <footer
    className={`footer-component ${props.className || ""} ${
      props.chat ? "chat" : ""
    }`}
  >
    <div className="body">
      <div className="left">
        © Health-Smart Hollistic Health for Seniors {new Date().getFullYear()}
      </div>
      <div className="grow" />
      <div className="right">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </div>
  </footer>
)

export default Footer
