import React from "react"

import "./spinner.scss"

var Spinner = props => (
  <div className={`spinner-component ${props.className || ""}`}>
    <div className="loader">Loading...</div>
  </div>
)

export default Spinner
