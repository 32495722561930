import React from "react"
import BorderTitle from "../border-title/borderTitle"

import "./modal.scss"

var Modal = props => (
  <div
    className={`modal-component ${props.className || ""} ${
      props.show ? "" : "hide"
    }`}
  >
    <div className="darken" onClick={props.onHideModal} />
    <div className="modal">
      <BorderTitle>{props.title}</BorderTitle>
      <div className="body">
        <div className="wrap">{props.children}</div>
      </div>
    </div>
  </div>
)

export default Modal
