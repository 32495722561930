import React from "react"
import MenuIcon from "mdi-react/MenuIcon"
import CloseIcon from "mdi-react/CloseIcon"
import AccountIcon from "mdi-react/AccountIcon"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"

import { mapIconNameToIconSource } from "../../utils/utils"
import Logo from "../logo/logo"
import UserIcon from "./../user-icon/userIcon"
import { NavHyperLinks } from "./../../utils/text"

import "./header.scss"

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      isMenuOn: false,
    }
  }

  onMenuIconClicked = () => {
    this.setState({ isMenuOn: !this.state.isMenuOn })
  }

  onMenuHide = () => {
    this.setState({ isMenuOn: false })
  }

  checkActivePath = path => {
    return globalHistory.location &&
      ~globalHistory.location.pathname.indexOf(path)
      ? "active"
      : ""
  }

  render() {
    return (
      <header
        className={`header-component ${
          this.checkActivePath("/chat") ? "chat" : ""
        }`}
      >
        <div className="max-width">
          <div className="left">
            <Link to="/">
              <Logo />
            </Link>
            <div
              onClick={this.props.userTabClick}
              className={`users ${this.props.userTabShowing ? "on" : ""}`}
            >
              <AccountIcon />
            </div>
          </div>
          <div className="flex-grow" />
          {this.props.user && (
            <>
              <div className="middle">
                <UserIcon
                  className="icon"
                  icon={mapIconNameToIconSource[this.props.user.icon]}
                  iconBackground={this.props.user.iconBackground}
                />
                <p>{this.props.user.name}</p>
              </div>
              <div className="flex-grow" />
            </>
          )}
          <div className="right">
            <div className="menu-icon">
              <MenuIcon onClick={this.onMenuIconClicked} />
            </div>
            <nav className={`menu ${this.state.isMenuOn ? "on" : ""}`}>
              <div className="close-icon">
                <CloseIcon onClick={this.onMenuIconClicked} />
              </div>
              <Link
                to="/"
                onClick={this.onMenuHide}
                className={`link `}
                activeClassName="active"
              >
                {NavHyperLinks.link1_Home}
              </Link>
              <div
                onClick={this.onMenuHide}
                className={`link wrap ${this.checkActivePath("/about/")}`}
              >
                <span className="category">{NavHyperLinks.link2_About.title}</span>
                <div className="hover-links">
                  <Link className="inner-link" to="/about/the-program">
                    {NavHyperLinks.link2_About.subtitle1}
                  </Link>
                  <Link className="inner-link" to="/about/our-team">
                    {NavHyperLinks.link2_About.subtitle2}
                  </Link>
                  <Link className="inner-link" to="/about/our-sponsors">
                    {NavHyperLinks.link2_About.subtitle3}
                  </Link>
                </div>
              </div>
              <Link
                to="/contact-us"
                onClick={this.onMenuHide}
                className={`link ${this.checkActivePath("/contact-us")}`}
              >
                {NavHyperLinks.link3_Contact}
              </Link>
              <Link
                onClick={this.onMenuHide}
                className={`link wrap ${this.checkActivePath(
                  "/program-components"
                )} ${this.checkActivePath("/components")}`}
              >
                <span className="category">{NavHyperLinks.link4_Program_Comp.title}</span>
                <div className="hover-links">
                  <Link
                    className="inner-link"
                    to="/program-components-year-one"
                  >
                    {NavHyperLinks.link4_Program_Comp.subtitle1}
                  </Link>
                  <Link
                    className="inner-link"
                    to="/program-components-year-two"
                  >
                    {NavHyperLinks.link4_Program_Comp.subtitle2}
                  </Link>
                </div>
              </Link>
              <Link
                to="/faq"
                onClick={this.onMenuHide}
                className={`link ${this.checkActivePath("/faq")}`}
              >
                {NavHyperLinks.link5_FAQ}
              </Link>
              <Link
                to="/resources"
                onClick={this.onMenuHide}
                className={`link ${this.checkActivePath("/resources")}`}
              >
                {NavHyperLinks.link6_Resources}
              </Link>
              {this.props.user ? (
                <>
                  {/* <div
                    onClick={this.onMenuHide}
                    className={`link wrap ${this.checkActivePath("/videos/")}`}
                  >
                    <span className="category">VIDEOS</span>
                    <div className="hover-links">
                      <Link
                        to="/videos/recorded-meetings"
                        className="inner-link"
                      >
                        RECORDED MEETINGS
                      </Link>
                      <Link to="/videos/tutorials" className="inner-link">
                        TUTORIALS
                      </Link>
                    </div>
                  </div> */}
                  <Link
                    onClick={this.onMenuHide}
                    to="/tutorials"
                    className={`link ${this.checkActivePath("/tutorials")}`}
                  >
                    Tutorials
                  </Link>
                  <Link onClick={this.onMenuHide} to="/chat" className="link">
                    <div className="wrap highlight red">Chat</div>
                  </Link>
                  <Link onClick={this.onMenuHide} to="/forum" className="link">
                    <div className="wrap highlight red">Forum</div>
                  </Link>
                  <div onClick={this.onMenuHide} className="link wrap">
                    <UserIcon
                      className="auth-icon category"
                      icon={mapIconNameToIconSource[this.props.user.icon]}
                      iconBackground={this.props.user.iconBackground}
                    />
                    <div className="hover-links">
                      <div
                        onClick={this.props.logoutClicked}
                        className="logout inner-link"
                      >
                        LOGOUT
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  onClick={() => {
                    this.onMenuHide()
                    this.props.loginClicked()
                  }}
                  className="link"
                >
                  <div className="highlight">LOGIN</div>
                </div>
              )}
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
