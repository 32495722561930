import React from "react"
import HeartPulseIcon from "mdi-react/HeartPulseIcon"
import FoodAppleIcon from "mdi-react/FoodAppleIcon"
import AccountGroupIcon from "mdi-react/AccountGroupIcon"
import quoteImage1 from "../images/quote1.png"
import quoteImage2 from "../images/quote2.png"
import quoteImage3 from "../images/quote3.png"
import quoteImage4 from "../images/quote4.jpg"
import personOne from "../images/person-1.png"
import personTwo from "../images/person-2.jpeg"
import sponsorHumana from "../images/sponsor-humana.jpg"
import sponsorUF from "../images/sponsor-uf.png"
import sponsorUFHealth from "../images/sponsor-uf-health.png"
import bg from "../images/banners/faq.png"

var homePage = {
  banner: {
    title:
      "Welcome to the Social Connection, Food Security, and Mental and Physical Health Promotion App!",
    subtitle:
      "On this app, you will be able to learn more about the Health-Smart Holistic Health Program for Seniors, post and receive answers to your health-related questions, and chat with other program participants.",
  },
  cardComponents: [
    {
      icon: HeartPulseIcon,
      title: "Physical & Mental Health",
      description:
        "Our minds and bodies are connected. We need to take care of both!",
      color: "#3393BD",
    },
    {
      icon: FoodAppleIcon,
      title: "Food Banks",
      description:
        "Nutritious food makes for a healthy life. Find food pantries in your area.",
      color: "#42B3E5",
    },
    {
      icon: AccountGroupIcon,
      title: "Social Connections App",
      description:
        "Our app let you chat with other participants from your phone! ",
      color: "#3393BD",
    },
    {
      title: "Read More Here!",
      color: "#42B3E5",
      link: "/program-components",
      button: "Read more",
    },
  ],
  about: {
    title: "About the Program",
    quote:
      "Mother Teresa once said, “I alone cannot change the world, but I can cast a stone across the waters to create many ripples.”",
    body:
      "The Health-Smart Holistic Health Program for Seniors aims to address the problems of social isolation, food insecurity, and financial insecurity that negatively impact the health and health-related quality of life of many seniors in Health Zone 1 (zip codes 32202, 32204, 32206, 32208, 32209, and 32254) of Jacksonville, Florida.  This program is supported by an $815,000 investment from the Humana Foundation and is led by Dr. Carolyn M. Tucker from the University of Florida.  Major components of the program include:",
  },
  quotes: [
    {
      src: quoteImage2,
      quote:
        "“The phone training was so helpful! My daughter gave me this phone, but she didn’t teach me how to use it. Now I feel more confident playing around with it!”",
    },
    {
      src: quoteImage3,
      quote:
        "“Health Smart gave me the chance to attend panels with real doctors, so that I could ask any questions I wanted!”",
    },
    {
      src: quoteImage4,
      quote:
        "“I was able to text and send my first picture ever to my grandson!”",
    },
  ],
}

var aboutTheProgramPage = {
  banner: {
    title: "About Health-Smart",
    subtitle: "One love! One heart! Let's get together and feel all right!",
  },
  aboutUs: {
    title: "ABOUT US",
    body: [
      <div>
        <p><b>
          Mother Teresa once said, “I alone cannot change the world, but I can cast a stone across the waters to create many ripples.”</b></p>

        <p>The Health-Smart Holistic Health Program for Seniors is a program that is designed to address the problems of social isolation, food insecurity, and financial insecurity that negatively impact the health and health-related quality of life of many seniors in Health Zone 1 of Jacksonville, FL. The program is a multi-component, multi-partnered program that helps empowers seniors to take charge of their social, mental, spiritual, and physical health under whatever conditions that exist in their lives.</p>
      </div>
      ],
  },
  howToJoinTheProgram: {
    title: "How do I join the program?",
    text: [
      <div>
        <p>If you are interested in joining the program, then you can contact us in the "Contact Us" section of the App located near the top of the page.</p> 

        <p>Please include your name, email, and a brief message to let us know that you are interested in joining. Once we receive your message, we will reach out to you for an eligibility screening.</p>
      </div>
    ]
  }
}

var aboutOurTeamPage = {
  banner: {
    title: "Our Team",
  },
  body: {
    title: "",
  },
  groups: [
    {
      groupName: "Research Team",
      people: [
        {
          name: "Dr. Carolyn Tucker",
          title: "Program Director and Principal Investigator",
        },
        { name: "Dr. Ross Jones", title: "Program Co-Investigator" },
        { name: "Mrs. Alexanderia Burwell", title: "Program Manager" },
        {
          name: "Mrs. Hermonyone Walker",
          title: "Church Pantry Program Coordinator",
        },
        {
          name: "Mrs. Deloris Rentz",
          title: "Lead Health-Smart Trainer and Community Research Coordinator",
        },
        {
          name: "Dr. Frederic Desmond",
          title: "Lead Health-Smart Trainer and Assistant Research Scientist",
        },
        { name: "Mr. Kieran Jacobsen", title: "Logistics Specialist" },
        { name: "Ms. Wafaa Ateyah", title: "Logistics Specialist" },
        { name: "Dr. Michael Marsiske", title: "Statistician" },
        {
          name: "Dr. Lori Bilello",
          title: "Corner Store and Mobile Pantry Program Coordinator",
        },
        { name: "Dr. Stephen Anton", title: "Program Collaborator" },
        { name: "Ms. Rhonda Hill", title: "Health-Smart Program Coordinator" },
        { name: "Mr. Dennis Stone", title: "Program Consultant" },
        { name: "Ms. Charlotte Hughes", title: "Public Relations Coordinator" },

        {
          name: "Dr. Lisa Hamilton",
          title: "Community Health Worker Consultant",
        },
        {
          name: "Mrs. Julia Henry-Wilson",
          title: "Postsecondary Success and Sustaining Employment Director",
        },
        {
          name: "Mr. Chester Daniel",
          title: "Consultant to the Jacksonville Urban League",
        },
      ],
    },
    {
      groupName: "Community Advisory Board",
      people: [
        {
          name: "Ms. Latonja Osborne",
          title: "Community Advisory Board Member",
        },
        {
          name: "Mr. Jerry McMillan Sr.",
          title:
            "Community Advisory Board Member and the Transportation and Partnership Development Coordinator",
        },
      ],
    },
    {
      groupName:
        "Clinical Staff at the Durkeeville and Soutel Health-Smart Holistic Health and Wellness Center",
      people: [
        { name: "Mrs. Anethette Swint", title: "Clinical Staff" },
        { name: "Mrs. Linda Wates", title: "Clinical Staff" },
        { name: "Ms. Mildred Pluviose", title: "Clinical Staff" },
        { name: "Ms. Nakeicha Dawson", title: "Clinical Staff" },
        { name: "Ms. Odille Thomas", title: "Clinical Staff" },
        // { name: "Ms. Tameka Wynn", title: "Clinical Staff" },
        // { name: "Dr. Tiara Cohen", title: "Clinical Staff" },
      ],
    },
    {
      groupName:
        "Year-1 Health-Smart Holistic Health Churches and Their Pastors",
      people: [
        {
          name: "Reverend Anthony L. Webster",
          title: "Pleasant Grove Primitive Baptist Church",
        },
        {
          name: "Bishop Kenneth Grover",
          title: "Greater Refuge Temple Church of our Lord, Inc.",
        },
        {
          name: "Pastor Matthew Spann and Co-Pastor Julia Spann",
          title: "Kingdom of God Outreach Ministries",
        },
        {
          name: "Elder Lee Harris",
          title: "Mt. Olive Primitive Baptist Church",
        },
        {
          name: "Pastor Robert LeCount Jr.",
          title:
            "Disciples of Christ Christian Fellowship Baptist Church, Inc.",
        },
        {
          name: "Reverend Carol Denise Phelps",
          title: "The Master's Touch Ministry, Inc.",
        },
        {
          name: "Reverend Dr. James B. Sampson",
          title: "First New Zion Missionary Baptist Church",
        },
        {
          name: "Reverend Dr. Marvin C. Zanders, II.",
          title: "St. Paul AME Church",
        },
        {
          name: "Reverend Marion A. Wise",
          title: "New Bethehem Missionary Baptist Church",
        },
        { name: "Minister William Holman", title: "The Church of Christ, Inc" },
      ],
    },
    {
      groupName: "Major Partners",
      people: [
        { name: "Dr. Leon Haley", title: " UF Health – Jacksonville" },
        {
          name: "Dean David Richardson",
          title: "University of Florida – Gainesville",
        },
        { name: "Dr. Richard Danford", title: "Jacksonville Urban League" },
        { name: "Mr. Michael Neff", title: "Feeding Northeast Florida" },
        { name: "Mr. Paul Sapia", title: "Humana, Jacksonville" },
        {
          name: "Dr. Irvin (PeDro) Cohen",
          title: "YMCA of Florida’s First Coast",
        },
      ],
    },
    {
      groupName: "App Team",
      people: [
        { name: "Edan Canning", title: "App Developer & Designer" },
        { name: "Ms. Gabriella C. Liwanag", title: "App Coordinator" },
        { name: "Zoe Frongillo", title: "App Developer" },
      ],
    },
  ],
}

var aboutOurSponsorsPage = {
  banner: {
    title: "Our Sponsors",
    subtitle:
      "I along cannot change the world, but I can cast a stone across the waters to create many ripples.",
  },
  sponsors: [
    {
      images: [sponsorHumana],
      name: "HUMANA FOUNDATION",
      text: [
        <div>
          <p>
            The Humana Foundation was established in 1981 as the philanthropic
            arm of Humana Inc., one of the nation’s leading health and
            well-being companies. Located in Louisville, Ky., the Foundation
            seeks to co-create communities where leadership, culture, and
            systems work to improve and sustain positive health outcomes.
          </p>
          <p>
            Humana and the Humana Foundation are dedicated to Corporate Social
            Responsibility. Our goal is to ensure that every business decision
            we make reflects our commitment to improving the health and
            well-being of our members, our employees, the communities we serve,
            and our planet.
          </p>
          <p>
            For more information, visit{" "}
            <a href="https://www.humanafoundation.org/">humanafoundation.org</a>
          </p>
        </div>,
      ],
    },
    {
      images: [sponsorUF, sponsorUFHealth],
      widthCorrection: [undefined, "90%"],
      name: "UNIVERSITY OF FLORIDA",
      text: [
        <p>
          The University of Florida, the state’s flagship university, serves
          almost 56,000 students from 49 states and most countries. With five
          professional schools and 200 research, service and education centers,
          bureaus, and institutes on a single 2,000-acre campus, UF offers
          educational opportunities matched by only seven universities
          worldwide. UF alumni total more than 415,000, with alumni residing in
          every U.S. state and more than 150 nations. It is ranked No. 7 in the
          most recent U.S. News and World Report’s list of public universities.
        </p>,
      ],
    },
  ],
}

var contactUsPage = {
  banner: {
    title: "Contact Us",
    subtitle: "You can submit a comment or a question in the spaces below! ",
  },
  locations: [
    {
      url:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3467.6306955994305!2d-82.3484384848943!3d29.643467582029924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e8a3760a358bb5%3A0xd6b96a9c9815e138!2sUniversity%20of%20Florida%20-%20Department%20of%20Psychology!5e0!3m2!1sen!2sus!4v1569382829940!5m2!1sen!2sus",
      name: "University of Florida - Department of Psychology",
      location: "Gainesville, FL",
      address: "945 Center Dr, Gainesville, FL 32603",
    },
    {
      url:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.564283807755!2d-81.66953578447917!3d30.33490911174644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e5b73f1164ee2d%3A0x565593ee33a4c78e!2sJacksonville%20Urban%20League!5e0!3m2!1sen!2sus!4v1569383189650!5m2!1sen!2sus",
      name: "Jacksonville Urban League",
      location: "Jacksonville, FL",
      address: "903 Union St, Jacksonville, FL 32204",
    },
  ],
}

var programComponentsPageYearOne = {
  banner: {
    title: "Program Components Year One",
    subtitle: "For a more in-depth look into our program ",
  },
  body: {
    title: "THE EIGHT PROGRAM COMPONENTS",
    text:
      "Our program is the “Health-Smart Holistic Health and Wellness Centers Program to Promote Social Connection and Food Security.”  As such, we focus on our participants through a holistic lens, because we understand that there are so many different things that can affect a person’s health and quality of life.  Our target community consists of Jacksonville seniors who are from minority, underserved, and/or low-income backgrounds.  We address a variety of different needs, such as access to food, connecting with others, and job security for family members, in order to holistically solve these issues.",
  },
}

var programComponentsPageYearTwo = {
  banner: {
    title: "Program Components Year Two",
    subtitle: "For a more in-depth look into our program ",
  },
  body: {
    title: "THE FIVE PROGRAM COMPONENTS",
    text:
      "Our program is the “Health-Smart Holistic Health and Wellness Centers Program to Promote Social Connection and Food Security.”  As such, we focus on our participants through a holistic lens, because we understand that there are so many different things that can affect a person’s health and quality of life.  Our target community consists of Jacksonville seniors who are from minority, underserved, and/or low-income backgrounds.  We address a variety of different needs, such as access to food, connecting with others, and job security for family members, in order to holistically solve these issues.",
  },
}

var faqPage = {
  banner: {
    title: "Ask Your Questions",
    // Used to be: Physical and Mental Health-Related Q&A
    // subtitle:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et",
  },
  categories: [
    {
      title: "MENTAL HEALTH",
      questions: [
        {
          question: "Where can I go to find therapy? ",
          answers: [
            "There are a variety of kinds of mental health therapy, some of which are more effective depending on the specific mental health condition and/or symptoms.  Additionally, the person who is seeking out mental health care can also influence the type of therapy received; for example, certain providers may be tailored toward children, women, men, and the like ",
            "Below are some mental health resources in the Jacksonville Area. ",
            "National Alliance on Mental Illness Jacksonville: Help Line: 904-724-7782",
            "Suicide Hotline: 800-273-TALK (8255) or 1-888-628-9454 for Spanish Speakers",
            `Mental Health Resource Center in Jacksonville <a href="http://www.mhrcflorida.com/home.html">mhrcflorida.com</a>`,
          ],
          image: bg,
        },
        {
          question:
            "Where can I learn about types of mental health treatment? ",
          answers: [
            "Mental health conditions are often treated with medication, therapy or a combination of the two.  However, there are many different types of treatment available, including Complementary & Alternative Treatments, self-help plans, and peer support. Treatments are very personal and should be discussed by the person with the mental health conditions and his or her team. ",
          ],
          image: bg,
        },
        {
          question:
            "What are the different types of mental health professionals? ",
          answers: [
            "There are many types of mental health professionals. Finding the right one for you may require some research. ",
          ],
          image: bg,
        },
        {
          question: "Where can I go to find a support group?",
          answers: [
            "Many people find peer support a helpful tool that can aid in their recovery. There are a variety of organizations that offer support groups for consumers, their family members and friends. Some support groups are peer-led, while others may be led by a mental health professional.",
          ],
          image: bg,
        },
        {
          question: "Where can I go to find inpatient care? ",
          answers: [
            "If you or someone you know is in crisis, inpatient care can help. Inpatient care can help people stabilize on new medications, adjust to new symptoms, or get the help they need.",
          ],
          image: bg,
        },
        {
          question: "Where can I go to find other local services? ",
          answers: [
            "There are likely plenty of resources that can be used to help you find mental health treatment in your community.  These resources can help you find the right therapist, and enable you to better understand viable treatment options and the treatment process.  ",
          ],
          image: bg,
        },
        {
          question:
            "Where can I learn more information about clinical trials? ",
          answers: [
            "Sometimes, consumers of mental health services may consider participating in a research study when they have not experienced improvement despite having tried a variety of medications and treatments. Research studies (also known as clinical trials) may involve the use of new medications or new treatment approaches whose safety and effectiveness is being tested. While we support innovation in the field, consumers should be cautioned that there are risks associated with clinical trials – make sure you’re aware of them before you enroll. ",
          ],
          image: bg,
        },
        {
          question:
            "Where can I learn more information about creating a Psychiatric Advance Directive? ",
          answers: [
            "Similar to a medical advance directive or a health care power of attorney, a psychiatric advance directive is a legal document completed in a time of wellness that provides instructions regarding treatment or services one wishes to have or not have during a mental health crisis, and may help influence his or her care. ",
          ],
          image: bg,
        },
      ],
    },
    {
      title: "SOCIAL CONNECTION",
      questions: [
        {
          question:
            "Why is it important to stay connected to others, especially as we age?",
          answers: [
            "We may understand how important physical activity is as we age, but many people do not realize the impact social health has on our health. Social interactions can be as effective as exercise in maintaining our quality of life. Adults who are letting go of their social interactions are putting themselves at risk for depression and dementia. Studies abound linking social health and cognitive function.",
          ],
          image: bg,
        },
      ],
    },
  ],
  additionalInformation: {
    title: "Additional Resources",
    //body: "Click on the link below to open or download booklets on the following health-related topics.\n\nPhysical Activity\nHealthy Eating and Drinking\nPortion Control\nStress and Depression Management\n\nLink to",
    body: [
      <div>
        <p>Click on the link below to open or download booklets on the following health-related topics:</p>
        <ul>
          <li>• Physical Activity</li>
          <li>• Healthy Eating and Drinking</li>
          <li>• Stress and Depression Management</li>
          <li>• Portion Control</li>
        </ul>
      </div>
    ],
    link: { label: "Link to Booklets", url: "https://drive.google.com/drive/folders/1bBDIAY3Gn2nPO9buaaY3cHLPmTMJEAh_?usp=sharing" }
  },
  pageDescription: {
    title: "Page Description",
    q1:{
      title: "How do I submit a FAQ to be answered?",
      answer : [
        <div>
          <p>At the bottom of this page there is the option to "Ask a Question" to be answered and then posted in the FAQ section. </p>
          <br></br>
          <p>You can type in the title of your question in the "Question Title" box, and then you can type the description of your question in the "Question Body" box. </p>
          <br></br>
          <p>Once you are finished, click the "Submit Question" button to send your question to us. All of the questions that are submitted are completely anonymous, meaning we will not know who sent us the question.</p>
        </div>
      ],
      linkDescrip : "If you would just like to ask a question, here is a link to the bottom of the page.",
    },
    q2:{
      title: "How long will it take for me to get an answer back?",
      answer : [
        <div>
          <p>Once you submit a FAQ to us, we will then consult a panel of volunteer experts to find out the answer. The panel has doctors, physical trainers, dieticians, psychologists, etc. who have volunteered to help answer the questions that are submitted.</p>
          <br></br>
          <p>We will try to have answers up as quickly as possible. However, depending on how complicated the question is and how long it takes to get a response from our panel, the response could take a week or more before it is posted. If you submit a question and don't see a response within 1-2 weeks. Please feel free to reach out to us directly in the "Contact Us" section of the App, or post your question in the "Forum" section.</p>
        </div>
      ],},
  },
  infoAlerts: {
    alert1: [
      <div>
        <b>NOTE:</b> Please read this first section before proceeding.
      </div>
    ],
    alert2: [
      <div>
        <b>NOTE:</b> To view the answer to the questions below, please <b>CLICK</b> on the question to expand it.
      </div>
    ],
  },
}


var resourcePage = {
  banner: {
    title: "Resources",
    subtitle: "For more resource information of our program.",
  },
  bookletResources:{
    title: "Health Promotion Booklets Resources (Downloads)",
    // Body is in the FAQ Page Object
  },
  mentalHealthResources:{
    title: "Mental Health Promotion Services and Resources",
    text: "Here you can download a PDF document of useful information about mental health and nearby services in Florida.",
    url: "https://drive.google.com/file/d/1Q9R9bll7MUKa9ZT2_v0Jn7fWn9MkGxAw/view?usp=sharing",
  },
  churchPantryResources:{
    title: "Jacksonville, FL | Food Pantry Locations (Year 3 - Last Updated 1/2022)",
    section1:{
      title: "Food Pantry Map | Jacksonville, FL",
      text: [
        <div>
          <p>For our target communities, church plays a large role in their lives.  It is the place where people can connect with others and connect with their faith.  As such, we have partnered with select churches to open food pantries so our participants will have access to healthy foods, while keeping close to their social connections.  This is especially important as our participants live in an area that is known to have food deserts, which are areas that have little access to places to buy affordable healthy food.</p>
          <p>Our food pantries provide fresh fruits and vegetables, along with whole grains and hearty breakfast foods.</p>
        </div>
      ]
    },
    section2:{
      title: "Food Pantry | Extended Information",
      alert : [
        <div>
          <b>NOTE:</b> Please contact the pantry for their current operating hours.
        </div>
      ],
      sheetURL: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSnLwVjGaHJn64eplQNd4eQ6n0-jZ6_NnCsHUc2ZsV4ZwuFVxV9J9QEjU1SDZRJGbMS_SzMsMHy5K8C/pubhtml?widget=true&amp;headers=false",
    },
    foodPantryQA: {
      categoryTitle: "Jacksonville, FL | Health-Smart Food Pantry Questions & Answers",
      q1:{
        title: "How can I find the closest food pantry to me?",
        resources: [
          {
            "description": "(OLD) Year-1 Church Pantry Map",
            "label": "Click here to see a map of the Year-1 pantries",
            "url": "https://drive.google.com/file/d/1VCZdWQ6sMGBzhpPKuo9RkVcWbCNr0zi9/view?usp=sharing"
        },
        {
            "description": "(OLD) Year-2 Church Pantry Map",
            "label": "Click here to see a map of the Year-2 pantries",
            "url": "https://drive.google.com/file/d/1Hc1ksC_8MmWZXYVVzg4EcHQDUC4JHcYK/view?usp=sharing"
        },
        /*
        {
          "description": "(CURRENT) Year-3 Church Pantry Map",
          "label": "Click here to see a map of the Year-3 pantries",
          "url": "https://drive.google.com/file/d/1d6IlUJxA1fhPpLQBdveKlYf-TXFEHC2_/view?usp=sharing"
        },
        */
          {
            "description": "Google Maps",
            "label": "Click here to open Google Maps",
            "url": "https://www.google.com/maps"
          }
      ],
        body: "We have over 20 church-based food pantries that we have partnered with as part of our Church-Based Food Pantry Program. There are at least 10 for each year of the program. There are maps with the addresses and names of the pantries listed in the resources below. \n\n An easy way to find them could be to type in the address of whichever pantry you would like to go to into Apple or Google Maps. To do so, open up Apple or Google Maps on either your smartphone or computer. Then type the address into the search bar in the top left of the screen. A link to google maps is available in the resources.",
      },
      q2:{
        title: "How can I improve on being healthier?",
        body: "It is important to stay hydrated. Health experts commonly recommend eight 8-ounce glasses, which equals to about 2 liters, or half a gallon a day. This is called the 8×8 rule.\n\nPlease refer to the link below to more healthy suggestions.",
        resources: [{
              "description": "Health and Nutrition Tips",
              "label": "click here to learn more",
              "url": "https://www.healthline.com/nutrition/27-health-and-nutrition-tips"
          }],
      },
      q3:{
        title: "What if I need financial assistance?",
        body: "Food pantries can be used to get free healthy and nutritious food to those in need.\n\nTo locate a food pantry near you - refer to link. \n\nFood Stamps (Supplemental Nutrition Assistance Program (SNAP)) \n\nSNAP (previously known as “food stamps”) is a federal program designed to provide basic nutritional needs to those struggling to acquire food during hard times.\nTo find more information on SNAP and if you are eligible - refer to link.",
        resources: [{
            "description": "Food Finder",
            "label": "click here to learn more",
            "url": "https://foodfinder.us/ "
        },
        {
            "description": "SNAP and Eligibility",
            "label": "click here to learn more",
            "url": "https://www.fns.usda.gov/snap/supplemental-nutrition-assistance-program"
        }],
      },

      /* Formatting
      q2:{
        title: "HOW CAN I FIND THE CLOSEST FOOD PANTRY TO ME?",
        resources : "",
        body: "We have over 20 church-based food pantries that we have partnered with as part of our Church-Based Food Pantry Program. There are at least 10 for each year of the program. There are maps with the addresses and names of the pantries listed in the resources below. \n\n An easy way to find them could be to type in the address of whichever pantry you would like to go to into Apple or Google Maps. To do so, open up Apple or Google Maps on either your smartphone or computer. Then type the address into the search bar in the top left of the screen. A link to google maps is available in the resources.",
      },
      */
    },

  },
  infoAlerts: {
    alert1: [
      <div>
        <b>NOTE:</b> To view the answer to the questions below, please <b>CLICK</b> on the question to expand it.
      </div>
    ],
  },
}

/* Please Note: 
  * You can ONLY edit the **existing** links specified here. 
    - Adding new entries will NOT do anything
    - Deleting the entries will just remove the text, NOT the page.  More work is required to delete the page.
  * This will ONLY change the text on the Navigation bar, NOT the URL of the page or the page header.
  * More info on this component: ./health-smart-frontend/src/components/header/header.js
  */
var NavHyperLinks = {
  link1_Home: "Home",
  link2_About:{
    title: "About",
    subtitle1: "THE PROGRAM",
    subtitle2: "OUR TEAM",
    subtitle3: "OUR SPONSORS",
  },
  link3_Contact: "Contact Us",
  link4_Program_Comp:{
    title: "Program Components",
    subtitle1: "YEAR ONE",
    subtitle2: "YEAR TWO",
  },
  link5_FAQ: "Ask Your Questions",
  link6_Resources: "Resources",
}

export {
  homePage,
  aboutTheProgramPage,
  aboutOurTeamPage,
  aboutOurSponsorsPage,
  contactUsPage,
  programComponentsPageYearOne,
  programComponentsPageYearTwo,
  faqPage,
  resourcePage,
  NavHyperLinks,
}
