import React from "react"
import { axios } from "../../utils/utils"
import Modal from "../modal/modal"
import Input from "../input/input"
import Button from "../button/button"

import "./loginModal.scss"
import Spinner from "./../spinner/spinner"

class LoginModal extends React.Component {
  constructor() {
    super()
    this.state = {
      username: "",
      password: "",
      usernameError: "",
      passwordError: "",
      loading: false,
    }
  }

  onInputChange = name => {
    return e => {
      this.clearErrors()
      this.setState({ [name]: e.target.value })
    }
  }

  onFormSubmit = e => {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
    if (this.validateForm()) {
      this.setState({ loading: true })
      axios
        .post(this.props.postURL, {
          username: this.state.username,
          password: this.state.password,
        })
        .then(res => {
          this.setState({ loading: false, username: "", password: "" })
          this.props.onHideModal()
          this.props.showSuccessToast()
          this.props.setUser({ ...res.data, token: res.headers.token })
        })
        .catch(err => {
          this.setState({ loading: false })
          if (err.response && err.response.status === 404) {
            this.props.showLoginErrorToast()
          } else {
            this.props.showErrorToast()
          }
        })
    }
  }

  validateForm = () => {
    if (!this.state.username.length > 0) {
      this.setState({ usernameError: "Please fill in username" })
      return false
    }
    if (!this.state.password.length > 0) {
      this.setState({ passwordError: "Please fill in password" })
      return false
    }
    return true
  }

  clearErrors = () => {
    this.setState({ usernameError: "", passwordError: "" })
  }

  render() {
    return (
      <div className={`login-modal-component ${this.props.className || ""}`}>
        <Modal
          title={this.props.title}
          onHideModal={this.props.onHideModal}
          show={this.props.show}
        >
          {this.state.loading ? (
            <Spinner />
          ) : (
            <form className="form" onSubmit={this.onFormSubmit}>
              <Input
                name="Username"
                error={this.state.usernameError.length > 0}
                errorMessage={this.state.usernameError}
                placeholder="Enter your username"
                value={this.state.username}
                onChange={this.onInputChange("username")}
              />
              <Input
                type="password"
                error={this.state.passwordError.length > 0}
                errorMessage={this.state.passwordError}
                name="Password"
                value={this.state.password}
                placeholder="*********"
                onChange={this.onInputChange("password")}
              />
              <Button className="login-button" invert>
                Login
              </Button>
            </form>
          )}
        </Modal>
      </div>
    )
  }
}

export default LoginModal
