import React from "react"

import "./userIcon.scss"

var UserIcon = props => (
  <div
    className={`user-icon-component ${props.className || ""} ${
      props.iconBackground
    } ${props.innerShadow ? "inner-shadow" : ""}`}
  >
    {props.icon && <img src={props.icon} alt="" />}
    {props.letter && <div className="letter">{props.letter}</div>}
  </div>
)

export default UserIcon
