import React from "react"

import "./borderTitle.scss"

var BorderTitle = props => (
  <h3 className={`border-title-component ${props.className || ""}`}>
    {props.children}
  </h3>
)

export default BorderTitle
