import React from "react"

import "./input.scss"

var Input = props => (
  <div
    className={`input-component ${props.className || ""} ${
      props.error ? "error" : ""
    }`}
  >
    <label htmlFor={props.name}>{props.name}</label>
    <p className="error-message">{props.errorMessage}</p>
    <input
      type={props.type || "text"}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  </div>
)

export default Input
