import React from "react"
import CheckIcon from "mdi-react/CheckIcon"
import CloseIcon from "mdi-react/CloseIcon"
import "./toast.scss"
import BorderTitle from "./../border-title/borderTitle"

var Toast = props => (
  <div
    className={`toast-component ${props.className || ""} ${
      props.error ? "error" : ""
    } ${props.success ? "success" : ""} ${props.iconBackground || ""} ${
      props.show ? "show" : ""
    } ${props.onClick ? "clickable" : "not-clickable"}`}
    onClick={props.onClick}
  >
    <div className="icon">
      {props.error && <CloseIcon />}
      {props.success && <CheckIcon />}
      {props.icon && <img src={props.icon} alt="" />}
      {props.letter && <div className="letter">{props.letter}</div>}
    </div>
    <BorderTitle className="toast-text">{props.children}</BorderTitle>
  </div>
)

export default Toast
