import React from "react"

import "./button.scss"

var Button = props => (
  <button
    onClick={props.onClick}
    disabled={props.disabled}
    className={`button-component ${props.className || ""} ${
      props.invert ? "invert" : ""
    } ${props.grey ? "grey" : ""} ${props.red ? "red" : ""}`}
    type={props.type}
  >
    {props.children}
  </button>
)

export default Button
