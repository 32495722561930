/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer"

import "./reset.css"
import "./layout.scss"
import LoginModal from "./login-modal/loginModal"
import Toast from "./toast/toast"
import { globalHistory } from "@reach/router"
import { axios, TOAST_TIMER } from "../utils/utils"

class Layout extends React.Component {
  constructor() {
    super()
    this.state = {
      user: null,
      loginModalShowing: false,
      showSuccessToast: false,
      showWrongBrowserToast: false,
      showErrorToast: false,
      showLoginErrorToast: false,
      showLogoutErrorToast: false,
      showLogoutSuccessToast: false,
      userTabShowing: false,
      activityInterval: null,
    }
  }

  componentDidMount() {
    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    if (!isChrome) this.showToast("showWrongBrowserToast", 15000)()
    this.getCurrentUserFromCookies()
  }

  componentWillUnmount() {
    this.clearActivityInterval()
  }

  setActivityInterval = () => {
    var minutes = 1000 * 60 * 5
    function activityInterval() {
      axios.post("/users/activity")
    }
    if (!this.state.activityInterval) {
      var interval = setInterval(activityInterval, minutes)
      this.setState({ activityInterval: interval })
    }
  }

  clearActivityInterval = () => {
    clearInterval(this.state.activityInterval)
    this.setState({ activityInterval: null })
  }

  getCurrentUserFromCookies = () => {
    axios
      .get("/users/me")
      .then(res => {
        this.setUser({ ...res.data, token: res.headers.token })
        this.showToast("showSuccessToast")()
      })
      .catch(err => {
        console.log(err)
      })
  }

  loginClicked = () => {
    this.setState({
      loginModalShowing: true,
    })
  }

  onHideModal = () => {
    this.setState({
      loginModalShowing: false,
    })
  }

  checkIfChat = () => {
    return Boolean(
      globalHistory.location &&
        ~globalHistory.location.pathname.indexOf("/chat")
    )
  }

  showToast = (toastName, time = TOAST_TIMER) => {
    return () => {
      this.setState(
        {
          [toastName]: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ [toastName]: false })
          }, time)
        }
      )
    }
  }

  setUser = user => {
    this.setState({ user }, () => {
      this.setActivityInterval()
    })
  }

  userTabClick = () => {
    this.setState({ userTabShowing: !this.state.userTabShowing })
  }

  logoutClicked = () => {
    axios
      .get("/users/logout")
      .then(() => {
        this.showToast("showLogoutSuccessToast")()
        this.setState({ user: null, users: null })
        navigate("/")
      })
      .catch(err => {
        console.log(err)
        this.showToast("showLogoutErrorToast")()
      })
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        ...this.props,
        userTabShowing: this.state.userTabShowing,
        user: this.state.user,
        userTabClick: this.userTabClick,
      })
    })
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <LoginModal
              postURL="/users/me"
              title="PARTICIPANT ONLY LOGIN"
              onHideModal={this.onHideModal}
              showSuccessToast={this.showToast("showSuccessToast")}
              showErrorToast={this.showToast("showErrorToast")}
              showLoginErrorToast={this.showToast("showLoginErrorToast")}
              show={this.state.loginModalShowing}
              setUser={this.setUser}
            />
            <Toast show={this.state.showSuccessToast} success>
              Login successful!
            </Toast>
            <Toast show={this.state.showErrorToast} error>
              Error logging in
            </Toast>
            <Toast show={this.state.showLoginErrorToast} error>
              Wrong username/password
            </Toast>
            <Toast show={this.state.showLogoutSuccessToast} success>
              Logout successful!
            </Toast>
            <Toast show={this.state.showLogoutErrorToast} error>
              Error logging out
            </Toast>

            <Header
              siteTitle={data.site.siteMetadata.title}
              user={this.state.user}
              loginClicked={this.loginClicked}
              userTabShowing={this.props.userTabShowing}
              userTabClick={this.userTabClick}
              logoutClicked={this.logoutClicked}
            />
            {this.checkIfChat() || <div className="header-fix" />}
            <div>
              <main>{children}</main>
              <Footer chat={this.checkIfChat()} />
            </div>
          </>
        )}
      />
    )
  }
}

export default Layout
