import axios from "axios"
import appleUserIcon from "../images/users/apple.svg"
import alienUserIcon from "../images/users/alien.svg"
import anchorUserIcon from "../images/users/anchor.svg"
import arrowUserIcon from "../images/users/arrow.svg"
import asparagusUserIcon from "../images/users/asparagus.svg"
import beetUserIcon from "../images/users/beet.svg"
import bellUserIcon from "../images/users/bell.svg"
import bikeUserIcon from "../images/users/bike.svg"
import brainUserIcon from "../images/users/brain.svg"
import breadUserIcon from "../images/users/bread.svg"
import broccoliUserIcon from "../images/users/broccoli.svg"
import bugUserIcon from "../images/users/bug.svg"
import cactusUserIcon from "../images/users/cactus.svg"
import catUserIcon from "../images/users/cat.svg"
import celeryUserIcon from "../images/users/celery.svg"
import cherriesUserIcon from "../images/users/cherries.svg"
import chickenUserIcon from "../images/users/chicken.svg"
import churchUserIcon from "../images/users/church.svg"
import circleUserIcon from "../images/users/circle.svg"
import cityUserIcon from "../images/users/city.svg"
import cloudUserIcon from "../images/users/cloud.svg"
import cloverUserIcon from "../images/users/clover.svg"
import clubUserIcon from "../images/users/club.svg"
import coffeeUserIcon from "../images/users/coffee.svg"
import cornUserIcon from "../images/users/corn.svg"
import cucumberUserIcon from "../images/users/cucumber.svg"
import diamondUserIcon from "../images/users/diamond.svg"
import dogUserIcon from "../images/users/dog.svg"
import duckUserIcon from "../images/users/duck.svg"
import eggsUserIcon from "../images/users/eggs.svg"
import elephantUserIcon from "../images/users/elephant.svg"
import fishUserIcon from "../images/users/fish.svg"
import flagUserIcon from "../images/users/flag.svg"
import flowerUserIcon from "../images/users/flower.svg"
import grapesUserIcon from "../images/users/grapes.svg"
import heartUserIcon from "../images/users/heart.svg"
import jellyfishUserIcon from "../images/users/jellyfish.svg"
import keyUserIcon from "../images/users/key.svg"
import leafUserIcon from "../images/users/leaf.svg"
import lemonUserIcon from "../images/users/lemon.svg"
import lightUserIcon from "../images/users/light.svg"
import mapleUserIcon from "../images/users/maple.svg"
import maskUserIcon from "../images/users/mask.svg"
import moonUserIcon from "../images/users/moon.svg"
import peachUserIcon from "../images/users/peach.svg"
import personUserIcon from "../images/users/person.svg"
import pineappleUserIcon from "../images/users/pineapple.svg"
import plantUserIcon from "../images/users/plant.svg"
import pumpkinUserIcon from "../images/users/pumpkin.svg"
import puzzleUserIcon from "../images/users/puzzle.svg"
import radishUserIcon from "../images/users/radish.svg"
import robotUserIcon from "../images/users/robot.svg"
import smileUserIcon from "../images/users/smile.svg"
import spadeUserIcon from "../images/users/spade.svg"
import squareUserIcon from "../images/users/square.svg"
import starUserIcon from "../images/users/star.svg"
import sunUserIcon from "../images/users/sun.svg"
import toastUserIcon from "../images/users/toast.svg"
import treeUserIcon from "../images/users/tree.svg"
import tulipUserIcon from "../images/users/tulip.svg"
import turtleUserIcon from "../images/users/turtle.svg"
import watercupUserIcon from "../images/users/watercup.svg"
import watermelonUserIcon from "../images/users/watermelon.svg"
import wheelUserIcon from "../images/users/wheel.svg"
import yinyangUserIcon from "../images/users/yinyang.svg"
import logoIcon from "../images/logoIcon.png"

function kebabCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .join("-")
}

var times = [
  ["second", 1],
  ["minute", 60],
  ["hour", 3600],
  ["day", 86400],
  ["week", 604800],
  ["month", 2592000],
  ["year", 31536000],
]
function timeAgo(timestamp, lastUpdated) {
  var date = new Date(timestamp)
  var NOW = new Date(lastUpdated)

  var diff = Math.round((NOW - date) / 1000)
  for (var t = 0; t < times.length; t++) {
    if (diff < times[t][1]) {
      if (t === 0) {
        return "Just now"
      } else {
        diff = Math.round(diff / times[t - 1][1])
        return diff + " " + times[t - 1][0] + (diff === 1 ? " ago" : "s ago")
      }
    }
  }
}

function className(classes) {
  var classString = ""
  for (let classKey in classes) {
    if (classes[classKey] === true) {
      classString += ` ${classKey}`
    }
  }
  return classString
}

var mapIconNameToIconSource = {
  apple: appleUserIcon,
  alien: alienUserIcon,
  anchor: anchorUserIcon,
  apple: appleUserIcon,
  arrow: arrowUserIcon,
  asparagus: asparagusUserIcon,
  beet: beetUserIcon,
  bell: bellUserIcon,
  bike: bikeUserIcon,
  brain: brainUserIcon,
  bread: breadUserIcon,
  broccoli: broccoliUserIcon,
  bug: bugUserIcon,
  cactus: cactusUserIcon,
  cat: catUserIcon,
  celery: celeryUserIcon,
  cherries: cherriesUserIcon,
  chicken: chickenUserIcon,
  church: churchUserIcon,
  circle: circleUserIcon,
  city: cityUserIcon,
  cloud: cloudUserIcon,
  clover: cloverUserIcon,
  club: clubUserIcon,
  coffee: coffeeUserIcon,
  corn: cornUserIcon,
  cucumber: cucumberUserIcon,
  diamond: diamondUserIcon,
  dog: dogUserIcon,
  duck: duckUserIcon,
  eggs: eggsUserIcon,
  elephant: elephantUserIcon,
  fish: fishUserIcon,
  flag: flagUserIcon,
  flower: flowerUserIcon,
  grapes: grapesUserIcon,
  heart: heartUserIcon,
  jellyfish: jellyfishUserIcon,
  key: keyUserIcon,
  leaf: leafUserIcon,
  lemon: lemonUserIcon,
  light: lightUserIcon,
  maple: mapleUserIcon,
  mask: maskUserIcon,
  moon: moonUserIcon,
  peach: peachUserIcon,
  person: personUserIcon,
  pineapple: pineappleUserIcon,
  plant: plantUserIcon,
  pumpkin: pumpkinUserIcon,
  puzzle: puzzleUserIcon,
  radish: radishUserIcon,
  robot: robotUserIcon,
  smile: smileUserIcon,
  spade: spadeUserIcon,
  square: squareUserIcon,
  star: starUserIcon,
  sun: sunUserIcon,
  toast: toastUserIcon,
  tree: treeUserIcon,
  tulip: tulipUserIcon,
  turtle: turtleUserIcon,
  watercup: watercupUserIcon,
  watermelon: watermelonUserIcon,
  wheel: wheelUserIcon,
  yinyang: yinyangUserIcon,
  admin: logoIcon,
}

const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

const ICON_COLORS = [
  "blue",
  "pink",
  "green",
  "red",
  "orange",
  "purple",
  "yellow",
  "silver",
  "grey",
  "maroon",
]

const TOAST_TIMER = 7000
const TIMESTAMP_RERENDER_TIMEOUT = 1000 * 30
const MAX_COMMENT_LENGTH = 600
const MAX_POST_LENGTH = 800
const MAX_POST_TITLE_LENGTH = 100
const FORUM_PAGE_SIZE = 5
const FORUM_PAGINATION_BUTTON_SIZE = 4
const FORUM_POSTS_SOCKET_NAME = "forumPosts"
const FORUM_COMMENTS_SOCKET_NAME = "forumComments"
const ADD_POST_REQUEST_TYPE = "add_post"
const LIKE_POST_REQUEST_TYPE = "like_post"
const UNLIKE_POST_REQUEST_TYPE = "unlike_post"
const VIEW_POST_REQUEST_TYPE = "view_post"
const ADD_COMMENT_REQUEST_TYPE = "add_comment"
const LIKE_COMMENT_REQUEST_TYPE = "like_comment"
const UNLIKE_COMMENT_REQUEST_TYPE = "unlike_comment"

// local/server
// const BASE_URL = "http://localhost:3000"
const BASE_URL = "https://healthsmartholistichealth.program.ufl.edu"

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8"
axios.defaults.withCredentials = true

export {
  className,
  kebabCase,
  timeAgo,
  axios,
  mapIconNameToIconSource,
  ICON_COLORS,
  LETTERS,
  BASE_URL,
  TOAST_TIMER,
  TIMESTAMP_RERENDER_TIMEOUT,
  MAX_COMMENT_LENGTH,
  FORUM_PAGE_SIZE,
  FORUM_PAGINATION_BUTTON_SIZE,
  FORUM_POSTS_SOCKET_NAME,
  MAX_POST_LENGTH,
  MAX_POST_TITLE_LENGTH,
  FORUM_COMMENTS_SOCKET_NAME,
  ADD_POST_REQUEST_TYPE,
  LIKE_POST_REQUEST_TYPE,
  UNLIKE_POST_REQUEST_TYPE,
  VIEW_POST_REQUEST_TYPE,
  ADD_COMMENT_REQUEST_TYPE,
  LIKE_COMMENT_REQUEST_TYPE,
  UNLIKE_COMMENT_REQUEST_TYPE,
}
